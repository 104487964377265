// importation de bootstrap
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import url('//db.onlinewebfonts.com/c/d97281294b63b449a93162fccd6121ec?family=Swis721+BT');
@import url('//db.onlinewebfonts.com/c/d58f65c7b063b8d1f4ed247e47e37e5b?family=Swis721+BT');

body{
    background-position: center;
    background-repeat: repeat;
    background-size: 7%;
    overflow-x: hidden;
    transition: all 200ms linear;
    margin: 0%;
    text-align: justify;
    font-family: 'Source Sans Pro', sans-serif;
}

tbody, td, tfoot, th, thead, tr
{
    border: solid;
    border-color: inherit;
}

header{
    position: relative;
}

#roue{
    margin-top: 25vh;
}

ul{
    list-style: none;
}

.bg-light {
    background-color: white !important;
    transition: all 200ms linear;
}

main{
    margin-top: 8vh;
    min-height: 100vh;
}

.absolute-center {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	transform: translateY(-50%);
}

h1{
    font-family: "Swis721 BT";
	font-size: 48px;
	line-height: 1.2;
	font-weight: 700;
	color: #212112;
	text-align: center;
}

h1 span{
	display: inline-block;
    transition: all 300ms linear;
	opacity: 1;
	transform: translate(0);
}

.btn img{
    width: 40px;
}

.btn-outline-orange {
    background-color: transparent;
    color: #ffa200;
    border: 3px solid #ffa200;
    border-color: #ffa200;
    transition-duration: 0.4s;
}

.btn-outline-success {
    color: black !important;
    background-color: transparent;
    border: 3px solid #0e8944aa;
    border-color: #0e8944aa;
    transition-duration: 0.4s;
}

.btn-success{
    background-color: #0e8944aa;
}

.btn-outline-orange:hover {
    background-color: #ffa200;
    color: #fffbea;
}

.btn-outline-success:hover {
    background-color: #0e8944aa;
    color: #fffbea !important;
}

#roue1 {
    position: absolute;
    width: 500px;
    left: -15vw;
    z-index: -1;
}

#roue2 {
    position: absolute;
    width: 100px;
    z-index: -1;
    left: 10vw;
}

#roue3 {
    position: absolute;
    z-index: -1;
    width: 300px;
    left: 75vw;
    bottom: -780px;
}

.form-control {
    width: 25vw;
    height: 3vh;
    margin-top: 20px;
    margin-right: 99px;
    margin-right: 406px;
}

.form-group{
    border: 0;
}

.btn {
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 5px;
    padding: 5px 25px;
    cursor: pointer;
    margin-top: 20px;
}

.wave-svg{
    width: 100%;
    border-radius: 5px;
}

a#reset-password{
    text-decoration: none;
    color: initial;
}
a{
    text-decoration: none;
    color: white;
}

