#alert-content{
    height: 20vh;
    display: flex;
    align-items: flex-end;
}
.alert{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    font-size: 24px;
    width: 100%;
}
.alert-success{
    background-color: #0e8944aa;
}

.alert-warning{
    background-color: #e7b123aa;
}

.alert-danger{
    background-color: #f04f3eaa;
}