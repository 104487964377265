.connexion-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#deconnexion-link{
    text-align: center;
}
#deconnexion-link a{
    color: blue;
}