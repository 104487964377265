#pagination{
    margin-top: 25vh;
    display: flex;
    justify-content: center;
}
section#articles{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

#grid-articles{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    gap: 30px;
    width: 60%;
}

.article-content{
    position: relative;
    padding: 0 50px;
}

.article-thumbnail{
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    border-radius: 10px;
}

.shadow-right{
    box-shadow: 25px 15px #0e8944aa;
}

.shadow-left{
    box-shadow: -25px 15px #0e8944aa;
}

.article-overlay{
    background-color: #00000088;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transform: scale(0);
    transition: .5s;
    border-radius: 20px;
}
.article-content:hover .article-overlay{
    transform: scale(1);
}

.article-categories{
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 2px;
}
.article-categories div{
    width: 100%;
    text-align: center;
}

/////////// FORM ///////////

section#articles form{
    transition: all .4s;
}

#search_article{
    display: none;
}
.show-nav{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}
#search_article_search{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
}

#search_article_categories .form-check{
    display: flex;
    flex-direction: row-reverse;
    margin: 15px 0;
}

#search_article_categories .form-check input[type="checkbox"].form-check-input{
    display: none;
}

#search_article_categories .form-check input[type="checkbox"].form-check-input + label{
    font-size: large;
}

#search_article_categories .form-check input[type="checkbox"].form-check-input + label::after{
    content:'';
    border: 0.2rem solid #0e8944aa;
    border-radius: 2px;
    padding: 0 0.7rem;
    margin-left: 0.3rem;
    transition: .2s;
}

#search_article_categories .form-check input[type="checkbox"].form-check-input:checked + label::after{
    background-color: #0e8944aa;
}

#search_article_Rechercher{
    width: 100%;
}

.navbar-toggler{
    display: block;
    position: relative;
    padding: 0;
    width: 45px;
    height: 45px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.navbar-toggler-icon-click {
    width: 0 !important;
    background: transparent !important;
}
.navbar-toggler-icon{
    display: block;
    width: 45px;
    height: 4px;
    background: #000000;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}

.navbar-toggler-icon::before,.navbar-toggler-icon::after{
    content: '';
    width: 45px;
    height: 4px;
    position: absolute;
    left: 0;
    background: #000000;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}
.navbar-toggler-icon::before{
    transform: translateY(-12px);
}
.navbar-toggler-icon::after{
    transform: translateY(12px);
}

.navbar-toggler-icon-click::before{
    transform: rotate(45deg);
}

.navbar-toggler-icon-click::after{
    transform: rotate(-45deg);
}
/////////// FORM ///////////