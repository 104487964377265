footer{
    position: relative;
}

#footerGif {
    animation-delay: 10s;
    position: relative;
    width: 100%;
    z-index: 1;
    bottom: 0;
}

#footer-content {
    position: absolute;
    display: flex;
    bottom: 15%;
    left: 25%;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;
}

#socialMedia {
    padding-right: 100px;
    transition: .3s;
}

#socialMedia a:hover{
    text-decoration: underline;
}

#newsletter {
    justify-content: space-evenly;
}

.newsletter{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 500px;
}

.newsletter h3 {
    font-size: 22px;
    color: #fffbea;
    text-align: center;
}

#copyright {
    width: 100%;
    z-index: 10;
    text-align: center;
    margin-top: -4px;
}
