.text-center5 {
    margin: 0;
    font-family: "Swis721 BT";
    font-size: 48px;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    color: #fffbea;
}

.titrecontact {
    font-family: "Swis721 BT";
    font-size: 48px;
    color: red;
    margin-top: 296px;
    margin-left: -730px;
}

#rouerouge1 {
    position: absolute;
    z-index: -1;
    left: 50vw;
    bottom: -400px;
    width: 500px;
}

#contact-relative{
    position: relative;
    margin-bottom: -6px;
    margin-top: 30vh;
}

#contact-content{
    position: absolute;
    top: 30vh;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#header-form{
    display: flex;
    height: 80px;
}

#contact div button{
    width: 100%;
}

#group-horraire-contact{
    background-color: #f04f3e;
    margin-bottom: -4px;
}

.horraire-contact{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 65px 0;
    justify-content: space-around;
}

.horraire-contact div{
    display: flex;
    align-items: center;
}

.horraire-contact *{
    color: #fffbea;
    font-size: 36px;
    padding-left: 15px;
}

.horraire-contact-logo{
    width: 75px;
}

