@media screen and (max-width: 1800px) {
    .horraire-contact{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 1295px) {
    #footer-content{
        flex-direction: column;
        bottom: 10%;
    }
}

@media screen and (max-width: 1200px) {
    #roue1,#roue2,#roue3,#rouerouge1{
        display: none;
    }
    .text-center5{
        color: black;
    }
    img#fond-contact{
        display: none !important;
    }
    footer{
        background-color: inherit !important;
    }
    #contact-content{
        position: static;
    }
    #group-horraire-contact{
        background-color: initial;
    }
    .horraire-contact * {
        color: black;
    }
    .horraire-contact-logo{
        display: none;
    }
    #socialMedia{
        margin-top: 0;
        padding-right: 0;
    }
    #newsletter{
        margin-top: 0;
    }
}

@media screen and (max-width: 1000px) {
    .text-center5{
        padding: 0 30px;
    }
    #contact-content form{
        padding: 0 30px;
    }
    .titrecontact{
        margin-top: 0;
        margin-left: 0;
    }
}

@media screen and (max-width: 950px){
    nav#navbar.navbar{
        justify-content: center;
        position: fixed;
    }

    a.navbar-brand{
        order: 2;
        margin: 0 auto;
    }

    a.navbar-brand img{
        width: 50px;
        height: 50px;
    }

    .nav-navbar-toggler{
        display: block;
        position: relative;
        padding: 0;
        width: 45px;
        height: 45px;
        border: none;
        background: transparent;
        cursor: pointer;
    }
    
    .nav-navbar-toggler-icon-click {
        width: 0 !important;
        background: transparent !important;
    }
    
    .nav-navbar-toggler-icon, .nav-navbar-toggler-icon::before,.nav-navbar-toggler-icon::after{
        margin: 0 20px;
    }
    
    .nav-navbar-toggler-icon{
        display: block;
        width: 45px;
        height: 4px;
        background: #000000;
        border-radius: 3px;
        transition: all .5s ease-in-out;
    }
    
    .nav-navbar-toggler-icon::before,.nav-navbar-toggler-icon::after{
        content: '';
        width: 45px;
        height: 4px;
        position: absolute;
        left: 0;
        background: #000000;
        border-radius: 3px;
        transition: all .5s ease-in-out;
    }
    .nav-navbar-toggler-icon::before{
        transform: translateY(-12px);
    }
    .nav-navbar-toggler-icon::after{
        transform: translateY(12px);
    }
    
    .nav-navbar-toggler-icon-click::before{
        transform: rotate(45deg);
    }
    
    .nav-navbar-toggler-icon-click::after{
        transform: rotate(-45deg);
    }

    #nav-content.open{
        transform: translate(0%); 
    }

    #nav-content{
        order: 1;
        position: absolute;
        background:  #fafafa;
        top: 100%;
        left: 0;
        height: 100vh;
        border-right: 1px solid #c5c5c6;
        transform: translate(-100%);
    }

    ul.navbar-nav{
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 50px 15px 20px;
    }

    ul.navbar-nav li{
        padding: 5px 0;
    }

    ul.navbar-nav li a{
        padding: 5px 0;
    }

    .horraire-contact div:last-child div{
        padding-right: 20px;
    }

    #footer-content{
        bottom: 5%;
    }
    
    #socialMedia div a img{
        width: 30px;
    }
    #socialMedia a{
        font-size: 8px;
    }
    .newsletter h3{
        font-size: 12px;
    }
}

@media screen and (max-width: 800px) {
    .pagination{
        flex-direction: column;
        height: inherit;
    }
}

@media screen and (max-width: 750px) {
    .newsletter{
        width: 450px;
    }
    // #subscribe_Souscrire{
    //     height: 20px;
    //     font-size: 6px;
    // }
}


@media screen and (max-width: 650px) {
    .alert{
        height: 50%;
        padding: 0 10px;
    }
    #addComment form{
        width: 100%;
    }
    .form-display{
        flex-direction: column;
        margin-left: 50px;
    }
    .navbar-toggler{
        float: right;
    }
    .form-display div.form-group{
        width: 100%;
    }
    input#comment_content,
    button#comment_envoyer{
        width: 85%;
    }
    .commentaire{
        flex-direction: column;
    }
    section#articles{
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 50px;
    }
    section#articles form{
        position: static;
        width: 100%;
        margin-bottom: 25px;
    }
    #grid-articles{
        grid-template-columns: repeat(1, 1fr);
    }
    #grid-articles div.article{
        grid-area: inherit !important;
    }
    
    .content{
        font-size: 24px;
        margin-top: 0;
    }
    .content div {
        width: 90%;
    }

    #footer-content{
        flex-direction: row;
        padding: 12px 0;
    }
    #socialMedia div .btn{
        padding: 0;
    }
    .newsletter{
        width: 100%;
    }
    #subscribe_Souscrire{
        height: 20px;
        font-size: 6px;
    }
    .newsletter .form-control{
        margin-top: 0;
    }
    #subscribe_email{
        height: 12px;
    }
}

@media screen and (max-width: 500px) {
    #footer-content{
        left: 12%; 
    }

}
@media screen and (max-width: 410px) {
    #footer-content{
        left: 6%; 
    }

}

@media screen and (max-width: 380px) {
    #footer-content{
        left: 0; 
    }

}